@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html {
  scroll-behavior: smooth;
}

@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
